<template>
  <div class="profile-page-container">
    <div class="header">
      <v-icon class="top-icon">mdi-image-outline</v-icon>
      <div class="title small-text">Tak Cię zapamiętamy</div>
    </div>

    <div class="gallery-grid" v-if="galleryData?.items?.length">
      <div v-for="(image, index) in galleryData?.items || []" :key="image.photoUrl">
        <img
            v-if="image?.photoUrl"
            :src="apiUrl + image.photoUrl?.replace(/\\/g, '/')"
            alt="Gallery Image"
            class="gallery-item"
            @click="openLightbox(index)"
        />
      </div>
    </div>

    <VueEasyLightbox
      :visible="visible"
      :imgs="images"
      :index="currentImgIndex"
      @hide="visible = false"
    />
  </div>
</template>

<script>
import VueEasyLightbox from 'vue-easy-lightbox'

export default {
  name: 'GalleryPage',
  components: {
    VueEasyLightbox
  },
  props: {
    galleryData: {
      type: Object,
      required: true,
      default: () => ({}),
    }
  },
  data() {
    return {
      apiUrl: process.env.VUE_APP_API_URL,
      visible: false,
      currentImgIndex: 0,
    };
  },
  computed: {
    images() {
      return this.galleryData?.items?.map(item =>
          item.photoUrl ? this.apiUrl + item.photoUrl.replace(/\\/g, '/') : ''
      ) || [];
    }
  },
  methods: {
    openLightbox(index) {
      this.currentImgIndex = index
      this.visible = true
    }
  },
};
</script>

<style lang="scss" scoped>
.header {
  margin-bottom: 16px;
}

.gallery-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  padding: 0;
  list-style: none;
  width: 100%;
  max-height: 80%;
  margin: 0 auto;
  overflow: auto;

  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr); // Two columns for larger images
    grid-gap: 16px; // Increase gap if needed to match the design
    width: 100%;
    max-width: 600px;
  }
}

.gallery-item {
  width: 100%; // Make sure the image takes up the full width of the grid column
  border-radius: 8px; // Adjust the border-radius to match the design
  height: auto; // Ensure the height is auto to maintain aspect ratio
  object-fit: cover; // Keep this to make sure images cover their area
  background: #ffffff;
  padding: 10px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

  &:hover {
    cursor: zoom-in;
  }
}

</style>
