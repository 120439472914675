<template>
  <div class="profile-page-container">
    <div class="header">
      <v-icon class="top-icon">mdi-briefcase-outline</v-icon>
      <div class="title small-text">Praca</div>
    </div>
    <div v-if="workData.photoUrl" class="photo-frame">
      <img :src="apiUrl + (typeof workData.photoUrl === 'string' ? workData.photoUrl.replace(/\\/g, '/') : '')" alt="Work Photo"/>
    </div>
    <h2 class="big-text">{{ workData.startYear }} - {{ workData.endYear }}</h2>
    <div class="big-text">{{ workData.place }}</div>
    <p class="small-text">{{ workData.description }}</p>
  </div>
</template>

<script>
export default {
  name: 'WorkPage',
  props: {
    workData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      apiUrl: process.env.VUE_APP_API_URL
    };
  },
};
</script>

<style lang="scss" scoped>
.profile-page-container {
  background-image: url('@/assets/images/profile_work_background.png');
  // Inherits styles from memorial-profile.scss
}

.title {
  margin-top: 8px;
  // Inherits small-text styles from memorial-profile.scss
}

.photo-frame {
  margin-top: 16px;
  width: 80%; // Example style, adjust as needed
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    height: 100%;
  }
}

.year {
  font-size: 48px; // Large font size for the year
  font-weight: bold;
  margin-top: 16px;
}
</style>
