<template>
  <div class="profile-page-container">
    <div class="hobbies-header">
      <v-icon class="top-icon">mdi-lightbulb-on-outline</v-icon>
      <h2 class="big-text">Zainteresowania</h2>
      <!--  <p class="small-text">Kliknij w kafelkę, aby dowiedzieć się więcej.</p> -->
    </div>
    <div class="hobbies-container">
      <swiper
          :fade-effect="{ crossFade: true }"
          :loop="true"
          :slide-active-class="'slide-active'"
          slides-per-view="auto"
          :space-between="20"
          class="swiper-slider"
      >
        <swiper-slide v-for="hobby in hobbyItems" :key="hobby.id" class="swiper-slide hobby">
          <div class="hobby-image-wrapper">
            <v-img :src="apiUrl + hobby.photoUrl" alt="Hobby Image"/>
          </div>
          <p class="hobby-name">{{ hobby.name }}</p>
          <p class="hobby-description">{{ hobby.description }}</p>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import {Swiper, SwiperSlide} from 'swiper/vue';

export default {
  name: 'HobbiesPage',
  components: {
    Swiper,
    SwiperSlide
  },
  props: {
    hobbyItems: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      apiUrl: process.env.VUE_APP_API_URL
    };
  }
};
</script>

<style lang="scss" scoped>
.hobbies-header {
  text-align: center;
}

.hobbies-container {
  width: 100%;
  height: fit-content !important;
  display: flex;
  overflow-x: hidden;
  margin: 20px 16px 0 !important;

  @media(min-width: 768px) {
    width: calc(100% + 60px);
  }
}

.hobby {
  width: 80% !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: #fff;
  padding: 16px;
  text-align: left;
  height: fit-content;

  @media (min-width: 480px) {
    width: 228px !important;
  }
}

.hobby-image-wrapper {
  height: 134px;
  display: flex;
  align-self: center;
}

.hobby-description {
  font-size: 16px;
  color: #333;
}

.hobby-name {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  margin-top: 8px;
}
</style>
