<template>
  <div class="profile-page-container second-page-class">
    <div class="header">
      <v-icon class="top-icon">mdi-star-four-points-outline</v-icon>
      <div class="date-and-month">{{ profileData?.birthDate?.day }}.{{ profileData?.birthDate?.month }}</div>
    </div>
    <div class="year-section">
      <h2 class="big-text">{{ profileData?.birthDate?.year }}</h2>

      <v-icon class="icon">mdi-pin</v-icon>

      <p class="description small-text">
        Miejsce urodzenia: {{ profileData?.birthPlace }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SecondPage',
  props: {
    profileData: {
      type: Object,
      default: () => ({})
    }
  }
};
</script>

<style lang="scss" scoped>
.profile-page-container {
  background-image: url('@/assets/images/profile_second_background.png'); /* New background image */
  background-size: cover; /* Cover the entire container */
  background-position: center; /* Center the background image */
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header .icon {
  font-size: 24px; /* Adjust size as needed */
}

.date-and-month {
  margin-top: 8px;
  font-size: 18px;
  font-weight: normal;
}

.year-section h1 {
  margin-top: 16px;
  font-size: 48px; /* Large font size for the year */
  font-weight: bold;
}

.description {
  text-align: center;
  margin-top: 8px;
}

.location-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
}

.location-arrow .icon {
  font-size: 24px; /* Adjust size as needed */
}

.location-name {
  margin-top: 8px;
}

/* Additional styles can be added as needed to match the design */
</style>
