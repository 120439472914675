<template>
  <div class="profile-page-container">
    <div class="header">
      <v-icon class="top-icon">mdi-star-outline</v-icon>
      <div class="title small-text">{{ eventDate }}</div>
    </div>
    <h2 class="big-text">{{ eventData.year }}</h2>
    <p class="big-text">{{ eventData.title }}</p>
    <p class="small-text">{{ eventData.description }}</p>
    <div v-if="eventData.photoUrl">
      <img :src="formattedPhotoUrl" alt="Event Photo" class="photo-frame"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ImportantEventsPage',
  props: {
    eventData: {
      type: Object,
      required: true
    }
  },
  computed: {
    eventDate() {
      return `${this.eventData.day}.${this.eventData.month}`;
    },
    formattedPhotoUrl() {
      return this.apiBaseUrl + this.eventData.photoUrl.replace(/\\/g, '/');
    },
    apiBaseUrl() {
      return process.env.VUE_APP_API_URL;
    }
  }
};
</script>

<style lang="scss" scoped>
.photo-frame {
  width: 100%; /* Full width of the container */
  height: 200px; /* Fixed height */
  object-fit: cover; /* Cover the frame without distorting the aspect ratio */
  display: block;
  margin: 16px auto;
}
</style>
